<template>
  <v-card>
    <v-system-bar>Errors</v-system-bar>
    <LogDataTable :items="items" :search="search" :loading="loading" />
  </v-card>
</template>

<script>
import LogDataTable from './components/LogDataTable.vue';
export default {
  name: 'LogErrors',
  components: { LogDataTable },
  props: ['search'],
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    this.items = await this.apiList({ resource: 'app/log', query: 'error' });
    this.loading = false;
  },
};
</script>
